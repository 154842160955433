<template>
  <div class="setVipTag">
    <pageTitle pageTitle="观众等级标签">
      <p class="switch__box">
        <vh-switch
          v-model="isOpen"
          active-color="#FB3A32"
          inactive-color="#CECECE"
          :inactive-value="0"
          :active-value="1"
          @change="changeVipTagSwitch"
          :active-text="reservationDesc"
        ></vh-switch>
        <span class="title_text">
          {{
            isOpen ? '已开启' : '开启后'
          }}，通过接口传参后账号下所有的直播间聊天区展示观众身份等级标签
        </span>
      </p>
    </pageTitle>
    <p class="title_note">
      注意：开启后在嵌入页地址中传入观众等级字段（member_level）即可，详细信息
      <a href="https://saas-doc.vhall.com/opendocs/show/1282" target="_blank" class="link">
        对接文档
      </a>
    </p>
    <div class="setVipTag-container">
      <!-- <div class="setVipTag-form"></div> -->
      <img class="preview_vip" src="@/common/images/plan-function/vip_preview.png" alt="" />
    </div>
  </div>
</template>

<script>
  import PageTitle from '@/components/PageTitle';

  export default {
    name: 'OfficialAccAuth',
    components: {
      PageTitle
    },
    data() {
      return {
        isOpen: 0
      };
    },

    methods: {
      changeVipTagSwitch(val) {
        this.$fetch('saveGeneralConfig', {
          type: 'member_level',
          content: JSON.stringify({ status: val })
        })
          .then(res => {
            if (res.code == 200) {
              this.$message.success('设置成功');
            } else {
              this.$message.error(res.msg);
              this.isOpen = val ? 0 : 1;
            }
          })
          .catch(err => {
            this.$message.error(err.msg);
          });
      },
      getGlobalConfig() {
        this.$fetch('getGeneralConfig', {
          type: 'member_level'
        }).then(res => {
          if (res.code == 200) {
            res.data.content && (this.isOpen = JSON.parse(res.data.content)?.status || 0);
          }
        });
      }
    },
    created() {
      this.getGlobalConfig();
    }
  };
</script>

<style lang="less" scoped>
  .setVipTag {
    font-size: 14px;
    .title_text {
      color: #999;
      vertical-align: middle;
    }
    .link {
      color: #3562fa;
    }
    .title_note {
      color: #999;
    }
  }
  .setVipTag-container {
    display: flex;
    .preview_vip {
      margin-top: 40px;
      width: 300px;
    }
    .setVipTag-form {
      width: 510px;
      margin-right: 64px;
    }
  }
</style>
